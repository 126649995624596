import { select } from "@utils/function";
// hamburger menu replace bootstrap

export default function HamburgerMenu() {
  document.addEventListener("click", (e) => {
    const target = e.target;
    const navbarToggler = target.closest(".navbar-toggler");
    const navbarCollapse = select(".navbar-collapse");
    const customNavbar = select(".custom-navbar")?.clientHeight;

    if (!navbarToggler) return;

    let checkAriaExpanded = target.getAttribute("aria-expanded");

    target.setAttribute(
      "aria-expanded",
      checkAriaExpanded == "false" ? true : false
    );

    document.documentElement.style.setProperty(
      "--custom-navbar-height",
      `${customNavbar + 10}px`
    );
    navbarCollapse.classList.toggle("show-menu");
  });
}
