import { getPathForVapor, select } from "@utils/function";

const ryderCupPartners = [
  "Aon",
  "BMW",
  "Capgemini",
  "Citi",
  "DPWorld",
  "Hilton",
  "Rolex",
];

window.realIndexSqiper = 0;

export default function RyderCupLogos(where) {
  const ryderCup = select(".rydercup-top");
  //   if (!ryderCup) return;

  const swiperPlace = ryderCup && where === "rydercup-top" ? "top" : "quickbar";

  const templateSwiper = swiperWrappwer(swiperPlace);

  if (ryderCup && where === "rydercup-top") {
    ryderCup.insertAdjacentHTML("afterbegin", templateSwiper);

    // get random partner
    const randomPartner =
      ryderCupPartners[Math.floor(Math.random() * ryderCupPartners.length)];

    // get index from array
    const findInderPartner = ryderCupPartners.findIndex(
      (parent) => parent === randomPartner
    );

    const configSwiper = {
      lazy: true,
      autoplay: {
        delay: 3000,
      },
      loop: true,
      // https://tree.taiga.io/project/eventhubhq-eventhubclient/issue/551
      initialSlide: findInderPartner,
      on: {
        init: function () {
          transitionSwiper();
        },
        realIndexChange: (swiper) => {
          // console.log(swiper.realIndex);
          window.realIndexSqiper = swiper.realIndex;
        },
      },
    };

    const swiperTop = new Swiper(`.swiper-top`, configSwiper);
  } else {
    ryderCupLogosInQuickbar(templateSwiper);
  }
}

/**
 * Transition swiper
 * set fade class and style opacity = 0
 */
function transitionSwiper() {
  // Get all elements with class "swiper-slide"
  const swiperSlides = document.querySelectorAll(".swiper-slide");

  // Set up options for the IntersectionObserver
  const options = {
    root: null,
    rootMargin: "0px",
    threshold: 0.5,
  };

  // Define a callback function to be called when an observed element enters the viewport
  const callback = (entries, observer) => {
    entries.forEach((entry) => {
      // console.log(entry.isIntersecting);
      if (entry.isIntersecting) {
        // If the element is in view and hasn't been faded in yet, fade it in and mark it as "faded"
        if (!entry.target.classList.contains("faded")) {
          entry.target.style.opacity = 1;
          entry.target.style.transition = "opacity 0.5s ease-out";
          entry.target.classList.add("faded");
        }
      } else {
        entry.target.removeAttribute("style");
        entry.target.style.opacity = 0;
        entry.target.classList.remove("faded");
      }
    });
  };

  // Create a new IntersectionObserver and observe each swiper-slide element
  const observer = new IntersectionObserver(callback, options);
  swiperSlides.forEach((swiperSlide) => observer.observe(swiperSlide));
}

/**
 * Generate template for swiper
 *
 * @param {String} where
 */
function swiperWrappwer(where) {
  const template = ryderCupPartners.map((partner) => {
    return Object.entries(HQ.Properties.partners)
      .map(([key, value]) => {
        const { logo_negative } = value;
        if (partner === key && logo_negative) {
          return `
          <div class="swiper-slide">
            <img src="${getPathForVapor(
              logo_negative
            )}" class="img-fluid" loading="lazy">
          </div>`;
        }
      })
      .join("");
  });

  const templateSwiper = `
    <div class="swiper-${where}">
      <div class="swiper-wrapper">
        ${template.join("")}
      </div>
    </div>
  `;

  return templateSwiper;
}

/**
 * A
 *
 * @param {String} logo1
 * @param {String} logo2
 */
function ryderCupLogosInQuickbar(template) {
  const rydercupQuickbar = select(".rydercup-quickbar");
  const rydercupTop = select(".rydercup-top");

  if (rydercupQuickbar) {
    window.swiperQuickbar?.destroy(true, true);
    rydercupQuickbar.remove();
  }

  const closeSlider = select(".active-slider .button-close-slider");
  if (!closeSlider) return;
  closeSlider.style.marginLeft = "0";

  const templateWrapper = `
    <div class="rydercup rydercup-quickbar">
      ${template}
    </div>
  `;

  const initSlider = rydercupTop
    ? initialSlider()
    : getRandomElementFromArray(ryderCupPartners);

  const activeSlider = select(".active-slider .title-slider");
  activeSlider.insertAdjacentHTML("afterend", templateWrapper);

  const configSwiper = {
    lazy: true,
    autoplay: {
      delay: 3000,
    },
    loop: true,
    initialSlide: initSlider, // initial slider
    reverseDirection: true,
    on: {
      init: function () {
        transitionSwiper();
      },
    },
  };

  window.swiperQuickbar = new Swiper(`.swiper-quickbar`, configSwiper);
}

/**
 * Initial slider
 */
function initialSlider() {
  const maxRyderCupPartners = ryderCupPartners.length;

  let realIndexSqiper = window.realIndexSqiper;

  return realIndexSqiper + 3 > maxRyderCupPartners
    ? maxRyderCupPartners - realIndexSqiper
    : realIndexSqiper + 3;
}

/**
 * Get random element from array and return index
 * @param {array} array
 * @returns number
 */
function getRandomElementFromArray(array) {
  const randomElement = array[Math.floor(Math.random() * array.length)];
  return array.findIndex((parent) => parent === randomElement);
}
